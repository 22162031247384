import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from '@mui/icons-material/Stop';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Breadcrumbs from "components/elements/Breadcrumbs";
import ReactMarkdown from 'react-markdown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { FaRegPlayCircle, FaRegPauseCircle, FaRegStopCircle } from 'react-icons/fa';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import LayersIcon from '@mui/icons-material/Layers';
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { GiBoxingGlove, GiOpenBook } from "react-icons/gi";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  useReactFlow,
} from 'reactflow';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,

} from "@mui/material";
import { useTheme } from "@emotion/react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
//import { styles } from "./Configurations";
import PublishIcon from "@mui/icons-material/Publish";
import { selectCurrentUser, selectCurrentToken } from "state/authSlice";

import {
  useGetPipelinesQuery,
  useLazyGetPipelineFlowByIdQuery,
  useGetObjectFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
  usePipelineGitDataMutation,
  useGetGitBranchesWithFileQuery,
  useGetPipelinesAuditQuery
} from "../state/apiSlice";
import Header from "../components/Header";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";

import TaskSequenceFlow from "./TaskSequenceFlow";
import Popup from "../components/Popup";
import ObjectForm from "../pages/ObjectForm";
import { ModeEditOutline } from "@mui/icons-material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStyles from "useStyles";


const Pipelines = () => {
  const navigate = useNavigate();
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const accessToken = useSelector(selectCurrentToken);

  const user = currentUser.login_id;
  const { id } = useParams();
  const location = useLocation();
  const { itemProjectData, itemProgramData } = location.state || {};
  const handleClick = async (row) => {
    navigate(`/tasks/${row.id}`, {
      state: {
        itemPipelineData: row,
        itemProjectData: itemProjectData,
        itemProgramData: itemProgramData,
      },
    });
  };

  const [loadingStates, setLoadingStates] = useState({});
  const [openPopupHelp, setOpenPopupHelp] = useState(false);

  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    fetch('/docs/pipeline/pipeline.md')
      .then(response => response.text())
      .then(data => setMarkdownContent(data))
      .catch(error => console.error('Error fetching pipeline.md:', error));
  }, []);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data: pipelineRecords, isLoading: isRecordLoading, error: recordError } =
    useGetPipelinesQuery({ id, user });
  const { data: formData, isLoading: isFormLoading, error: formError } =
    useGetObjectFormQuery({
      name: "Pipeline",
      user: user,
    });
  const [getPipelineFlowById] = useLazyGetPipelineFlowByIdQuery();
  const [publishMessage, setPublishMessage] = useState("");

  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();
  const [updateGitpipeline] = usePipelineGitDataMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);

  const [pipelineTasks, setPipelineTasks] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [openTaskSeqPopup, setOpenTaskSeqPopup] = useState(false);
  const [rowpublish, setRowPublish] = useState(null);
  const [rowJobs, setRowJobs] = useState();
  const [toggle, setToggle] = useState(true);

  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showRunDialog, setShowRunDialog] = useState(false);

  const [showPublishCancelDialog, setShowPublishCancelDialog] = useState(false);
  const [publishSuccessDialogOpen, setPublishSuccessDialogOpen] =
    useState(false);
  const publishRow = async (row) => {
    setShowPublishDialog(true);
    setRowPublish(row);
  };
  const runFromBranch = async (row) => {
    setShowRunDialog(true);
    setRowPublish(row);
    setRowJobs(row);
  };

  const handlePublishDialogClose = () => {
    setShowPublishDialog(false);
    setShowRunDialog(false);
    setShowPublishCancelDialog(true);
  };

  const handleDialogClose = () => {
    setShowPublishCancelDialog(false);
  };
  const [branchNames, setBranchNames] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("main");
  const baseURL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    // Simulated branch names for example
    const fetchedBranchNames = ["main", "feature-branch", "bug-fixes"];
    setBranchNames(fetchedBranchNames);
  }, []);

  const projectId = rowpublish?.project_id;
  const pipelineName = rowpublish?.pipeline_name;

  const { data: branchData } = useGetGitBranchesWithFileQuery({
    variables: {
      project: projectId,
      name: pipelineName,
      type: "pipeline",
    },
  });

  useEffect(() => {
    if (branchData) {
      const branchesWithStatus = branchData.branches.map((branch) => {
        return {
          ...branch,
          statusMessage: branch.pathExists
            ? "File Path Exists  "
            : "File Path Does Not Exist",
        };
      });
      setBranchNames(branchesWithStatus);
    }
  }, [branchData]);

  // Update the handlePublishConfirm function in your React component
  const handlePublishConfirm = async () => {
    setShowPublishDialog(false);
    setShowRunDialog(false);

    const publishRecord = JSON.parse(JSON.stringify(rowpublish));
    try {
      if (selectedBranch) {
        const response = await updateGitpipeline({
          id: publishRecord?.id,
          branch: selectedBranch,
        });
        if (response.data.status === 200) {
          // Successful update when the file already exists
          setPublishMessage(response.data.message); // Use the message from the server
        } else {
          // Handle other error cases
          setPublishMessage("An error occurred while publishing the pipeline.");
        }
      } else {
        const response = await updateGitpipeline({
          id: publishRecord?.id,
          branch: "main",
          login_id: user

        });

        if (response.data.status === 200) {
          // Successful update when the file already exists
          setPublishMessage(response.data.message); // Use the message from the server
        } else {
          // Handle other error cases
          setPublishMessage("An error occurred while publishing the pipeline.");
        }
      }
    } catch (error) {
      console.log(error);
      setPublishMessage("An error occurred while publishing the pipeline.");
    }
    setPublishSuccessDialogOpen(true);
  };

  const handlePublishSuccessDialogClose = () => {
    setPublishSuccessDialogOpen(false);
  };


  if (!isFormLoading && initValue === null && !isRecordLoading) {
    if (formError) {
      console.error("Error fetching form data:", formError);
    } else {
      setInitValue(formData[0]?.initialvalues);
      setFieldList(formData[0]?.field_list);
    }
  }

  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "pipeline", data: values, user: user });
    } else {
      await updateObject({ object: "pipeline", data: values, user: user });
    }

    const delayMilliseconds = 3000;
    await new Promise((resolve) => setTimeout(resolve, delayMilliseconds));
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  let pageTitle = "Pipelines";
  let pageSubTitle = "View/Manage Pipelines.";

  if (typeof id !== "undefined") {
    pageTitle = "Pipelines";
    pageSubTitle = "See your list of Pipelines.";
    // pageSubTitle = "Details of Pipelines of Project  : " + itemProjectData?.project_name;
  }
  const [rowValue, setRowValue] = useState("");

  const handleTaskSequenceClick = async (id, row) => {
    const { data } = await getPipelineFlowById(id);
    if (data) {
      setPipelineTasks(data);
      setOpenTaskSeqPopup(true);
      setRowValue(row);
      // open Open Popup for Task Sequencing.
    }
  };

  function handleDownloadClick() {
    const logsData = logs[loggerID?.id];
    if (!logsData) {
      return;
    }
    const blob = new Blob([logsData], { type: "text/plain" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${loggerID?.pipeline_name} ${loggerID?.id}.txt`;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }



  const TerminateButton = ({ row }) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleTerminate = async () => {
      // Send a request to the server to terminate the script for the selected pipeline
      try {
        const response = await fetch(
          `${baseURL}/api/pipelines/terminate-python-script/${row.pipeline_name}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.message) {
            // Successful termination
            console.log(data.message, "Script termination request sent successfully");
            toast.success(data.message);
          }
        } else {
          // Error in termination
          const errorData = await response.json();
          console.error("Failed to send script termination request:", errorData.error);
          toast.error(errorData.error);
        }
      } catch (error) {
        console.error("Error sending script termination request:", error);
        toast.error("Error sending script termination request");
      }
    };


    return (
      <Box
        onClick={handleTerminate}
        sx={{
          display: "flex",
          alignItems: "center", // Vertically center
          justifyContent: "center", // Horizontally center
          textAlign: "center",
        }}
      >
        <FaRegStopCircle
          style={{
            fontSize: '18px', // Increase the size of the icon
            color: 'red', // Set the color to red
          }}
          sx={{
            "&:hover": {
              fontSize: '25px', // Change size on hover
              cursor: 'pointer',
            },
          }} />
      </Box>
    );
  };


  const RunButton = ({ row }) => {
    const isExecuteEnabled = row.option_execute === "1";
    const [isPlaying, setIsPlaying] = useState(false);
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center", // Vertically center
            justifyContent: "center", // Horizontally center
            textAlign: "center",
            ...(loadingStates[row.id]
              ? {
                color: "black",
                cursor: "not-allowed",
              }
              : {}),
          }}
          onClick={() => {
            if (isExecuteEnabled) {
              if (isPlaying) {
                console.log("isPlaying", isPlaying);
              } else {
                runFromBranch(row);
              }

              setIsPlaying(!isPlaying);
            }
          }}
          disabled={loadingStates[row.id] || !isExecuteEnabled}
        >
          <PlayCircleIcon
            sx={{
              cursor: "allowed",
              fontSize: '20px',
              color: isPlaying ? 'red' : 'green',
              transition: 'font-size 0.3s ease',
              "&:hover": {
                fontSize: '25px', // Change on hover
                cursor: 'pointer',
              },
            }}
          />
        </Box>
      </Box>
    );
  };

  const runPythonScript = async (rowJobs) => {
    setShowRunDialog(false);

    if (!rowJobs || !rowJobs.id) {
      console.error("rowJobs is undefined or does not have the 'id' property.");
      return;
    }
    const { id, pipeline_name } = rowJobs;

    const project_id = rowJobs?.project_id;

    const apiUrl = `${baseURL}/api/projects/${project_id}`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch project name");
      }

      const data = await response.json();
      const project_name = data.project_name;

      try {
        const response = await fetch(
          `${baseURL}/api/pipelines/run-python-script`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              project_name,
              pipeline_name,
              selectedBranch,
            }),
          }
        );


        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const reader = response.body.getReader();
        let output = "";

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          const chunk = new TextDecoder().decode(value);

          setLogs((prevLogs) => ({
            ...prevLogs,
            [id]: (prevLogs[id] || "") + chunk,
          }));

          output += chunk;

        }
      } catch (error) {
        console.error("Error running Python script:", error);
      } finally {
        setLoadingStates((prevLoadingStates) => ({
          ...prevLoadingStates,
          [id]: false,
        }));
        openModal();
      }
    } catch (error) {
      console.error("Error fetching project name:", error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setOpenPopupHelp(false);

  };

  const [logs, setLogs] = useState([]);
  const [loggerID, setLoggerId] = useState(null);
  const LoggerButton = ({ row }) => {
    const isLogAvailable = logs[row.id] !== undefined;

    return (
      <Box>
        <Box
          onClick={() => {
            if (isLogAvailable) {
              openModal(logs[row.id]);
              setLoggerId(row);
            }
          }}
        >
          <TextSnippetIcon sx={{ color: isLogAvailable ? "green " : "black", fontSize: "20px", cursor: isLogAvailable ? "pointer" : "default" }} />
        </Box>
      </Box>
    );
  };

  const Status = ({ row }) => {
    const { data, error } = useGetPipelinesAuditQuery({
      pipelineName: row.pipeline_name,
      id: row.id,
    });

    const buttonState = {
      label: "NEW",
      Icon: AutoAwesomeIcon, // Default icon for "NEW"
      color: "black",
    };

    if (data) {
      if (data[0]?.audit_value === "COMPLETED") {
        buttonState.Icon = CheckCircleIcon;
        buttonState.color = "black";
      } else if (data[0]?.audit_value === "FAILED") {
        buttonState.Icon = CancelIcon;
        buttonState.color = "black";
      }
    }
    const IconComponent = buttonState.Icon;
    return (
      <Box>
        <span
          style={{
            cursor: loadingStates[row.id] ? "not-allowed" : "default",
            display: "flex",
            alignItems: "center", // Vertically center
            justifyContent: "center", // Horizontally center
            textAlign: "center",
            paddingLeft: "15px",
          }}
        >
          <IconComponent style={{ marginRight: "5px", color: buttonState.color }} />
        </span>
      </Box>
    );
  };



  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    { field: "pipeline_name", headerName: "Pipeline ", flex: 1.1 },
    // { field: "pipeline_cd", headerName: "Pipeline Code", flex: 1 },
    { field: "pipeline_description", headerName: "Description", flex: 1.1 },
    {
      field: "pipeline_sequence",
      headerName: "Seq. No",
      flex: 0.38,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 0.35,
      headerAlign: "center",
      align: "center"
    },
    {
      field: "Task Sequence",
      Header: "Task Sequence",
      flex: 0.68,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Box
            sx={{
              color: "var(--primary-color)",
              cursor: "pointer",
            }}
            onClick={() => handleTaskSequenceClick(row.id, row)}
          >
            <Tooltip title={`Sequence`}>
              <LayersIcon />
            </Tooltip>
          </Box>
        );
      },
    },

    {
      field: "update",
      headerName: "Update",
      flex: 0.38,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        const projectRecord = pipelineRecords?.find(
          (record) => record.id === row.id
        );

        // Check if projectRecord exists and option_write is 1
        const isWriteEnabled =
          projectRecord && projectRecord?.option_write === "1";

        const buttonStyles = isWriteEnabled
          ? styles.editButton
          : styles.editButtonDisable;

        return (
          <Button
            color="secondary"
            onClick={() => {
              openInPopup(row);
            }}
            disabled={!isWriteEnabled}
          >
            <ModeEditOutline sx={buttonStyles} />
          </Button>
        );
      },
    },
    {
      field: "opentasks",
      headerName: "Open Tasks",
      flex: 0.55,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row: { id }, row }) => {
        return (

          <Box
            component={Link}
            to={`/tasks/${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleClick(row);
            }}
            sx={{
              cursor: "pointer",
            }}
          >
            <GiOpenBook style={{
              color: "black",
              fontSize: "14px",
            }} />
          </Box>
        );
      },
    },
    {
      field: "publish",
      headerName: "Publish",
      flex: 0.4,
      headerAlign: "center",
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Box>
            <Button
              color="secondary"
              onClick={() => {
                publishRow(row);
              }}
              style={{ color: '#333' }}
            >
              <PublishIcon sx={styles.PublishIcon} />
            </Button>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.35,
      renderCell: ({ row }) => <Status row={row} />,
    },
    // {
    //   field: "run",
    //   headerName: "Run",
    //   flex: 0.5,
    //   renderCell: ({ row }) => <RunButton row={row} />,
    // },
    // {
    //   field: "TERMINATE",
    //   headerName: "Stop",
    //   flex: 0.5,
    //   renderCell: ({ row }) => <TerminateButton row={row} />,
    // },
    // {
    //   field: "logger",
    //   headerName: "Log",
    //   flex: 0.7,
    //   renderCell: ({ row }) => {
    //     return <LoggerButton row={row} log={logs[row.id]} />;

    //   },
    // },
    {
      field: "Run Stop Log",
      headerName: "Run Stop Log",
      headerAlign: "center",
      flex: 0.65, // Adjust the flex value as needed to accommodate all buttons
      renderCell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px", width: "100%" }}>
          <RunButton row={row} style={{ flex: 1 }} />
          <TerminateButton row={row} style={{ flex: 1 }} />
          <LoggerButton row={row} log={logs[row.id]} style={{ flex: 1 }} />
        </div>
      )
    }
  ];

  const breadcrumbPaths = [{ path: "/", label: "Home" }];
  if (itemProgramData?.program_name !== undefined) {
    breadcrumbPaths.push({
      path: "/programs",
      label: `Programs ( ${itemProgramData?.program_name})`,
    });
  }
  if (itemProjectData?.project_name !== undefined) {
    breadcrumbPaths.push({
      path: `/projects/${itemProjectData.program_id}`,
      label: `Projects ( ${itemProjectData.project_name})`,
    });
  }

  breadcrumbPaths.push({ path: "/pipelines", label: "Pipelines" });

  const val4 = itemProjectData?.option_write;
  const isButtonDisabled = val4 > 0;

  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Popup
          title={`Python Script Output for Pipeline Name :  ${loggerID?.pipeline_name} ${loggerID?.id} `}
          openPopup={modalIsOpen}
          setOpenPopup={setModalIsOpen}
          width="lg"
          yesBtn={false}
          noBtn={false}
          okBtn={false}
          closeBtn={true}
          handleCloseBtnClick={closeModal}
        >
          <div>
            <pre>{logs[loggerID?.id]}</pre>
            <Button onClick={handleDownloadClick} sx={styles.downloadButton}>
              Download
            </Button>
          </div>
        </Popup>
        <Popup
          title="Publish Pipeline To Github?"
          openPopup={showPublishDialog}
          width={"md"}
          setOpenPopup={setShowPublishDialog}
          handleYesBtnClick={handlePublishConfirm}
          handleNoBtnClick={handlePublishDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p>
            Do you want to publish this pipeline {rowpublish?.pipeline_name} to
            GitHub
          </p>

          <FormControl style={{ width: "20%" }}>
            <InputLabel>Select Branch</InputLabel>
            <Select
              value={selectedBranch}
              onChange={(event) => setSelectedBranch(event.target.value)}
              style={{ width: "100%" }}
            >
              {branchNames.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display whether path exists or not */}
          {selectedBranch && (
            <p
              style={{
                color: branchNames.find(
                  (branch) => branch.branchName === selectedBranch
                )?.pathExists
                  ? "green"
                  : "red",
              }}
            >
              {branchNames.find(
                (branch) => branch.branchName === selectedBranch
              )?.pathExists
                ? `Pipeline : ${rowpublish?.pipeline_name} exists in ${selectedBranch} branch. Do you want to overwrite?`
                : `Pipeline : ${rowpublish?.pipeline_name} Does Not Exist in ${selectedBranch} branch. Do you want to create it?`}
            </p>
          )}
        </Popup>
        <Popup
          title="RUN JOB"
          openPopup={showRunDialog}
          width={"md"}
          setOpenPopup={setShowRunDialog}
          handleYesBtnClick={() => {
            runPythonScript(rowJobs);
          }}
          handleNoBtnClick={handlePublishDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p>
            Select the branch from where you need to run this pipeline{" "}
            {rowJobs?.pipeline_name}
          </p>

          <FormControl style={{ width: "20%" }}>
            <InputLabel>Select Branch</InputLabel>
            <Select
              value={selectedBranch}
              onChange={(event) => setSelectedBranch(event.target.value)}
              style={{ width: "100%" }}
            >
              {branchNames.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Popup>

        <Popup
          title="Canceled"
          openPopup={showPublishCancelDialog}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>
            Publishing pipeline: {rowpublish?.pipeline_name} record to GitHub is
            Canceled.
          </p>
        </Popup>

        <Popup
          title="Canceled"
          openPopup={showPublishCancelDialog}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>
            Job Execution for pipeline: {rowJobs?.pipeline_name} is Canceled.
          </p>
        </Popup>
        <Popup
          title="Pipeline Published"
          openPopup={publishSuccessDialogOpen}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handlePublishSuccessDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <div style={{ padding: "20px", textAlign: "left" }}>
            <strong>
              <p>
                Pipeline Name: {rowpublish?.pipeline_name} has been successfully
                uploaded to GitHub.
              </p>
              <p>Branch: {selectedBranch ? selectedBranch : "main"}</p>
              <p>Created by: {rowpublish?.created_by}</p>
              <p>Updated by: {rowpublish?.updated_by}</p>{" "}
            </strong>
          </div>

          {/* <p>{publishMessage} Pipeline: {rowpublish?.pipeline_name} is uploaded to GitHub{" "}
            {selectedBranch ? selectedBranch : "main"} branch Successfully.</p> */}
        </Popup>
        <Header title={pageTitle} />

        <Breadcrumbs breadcrumbPaths={breadcrumbPaths} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header subtitle={pageSubTitle} />


          <Box display="flex" alignItems="center" gap={2}>
            <ToggleButtonGroup
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="Toggle between Active and All"
              sx={{
                borderRadius: "80px",

              }}
            >
              <ToggleButton
                value={true}
                aria-label="All"
                sx={{
                  ...styles.myBackground,

                  backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: !toggle ? "#ffffff" : styles.myBackground.color,
                  '&:hover': {
                    backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="Active"
                sx={{
                  ...styles.myBackground,
                  backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: toggle ? "#ffffff" : styles.myBackground.color, // Text color changes based on toggle state
                  '&:hover': {
                    backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                    color: toggle ? "#ffffff" : styles.myBackground.color, // Ensure hover color is consistent with the toggle state
                  },
                }}
                disabled={!toggle}
              >
                Active
              </ToggleButton>
            </ToggleButtonGroup>



            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(initValue);
              }}

              sx={{
                ...styles.myBackground,
                ...(!isButtonDisabled && { backgroundColor: "var(--secondary-color) !important" }),
                ...(!isButtonDisabled && { color: "white !important" }),
              }}
              disabled={!isButtonDisabled}
            >
              Create
            </Button>


            <Button
              onClick={() => {
                setOpenPopupHelp(true);
              }}
              sx={{
                ...styles.myBackgroundToggle,
              }}
            >
              Help
            </Button>

          </Box>
        </Box>
      </Box>
      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: '100vh', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>

        ) : isRecordLoading ? (
          <p>Loading...</p>
        ) : pipelineRecords ? (
          <Box
            m="10px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-row.Mui-odd": {
                backgroundColor: "var(--tertiary-color) !important",
              },
              "& .MuiDataGrid-row.Mui-disabled-row": {
                backgroundColor: "lightgrey",
              },


              "& .MuiDataGrid-root": {
                border: "none",
                fontWeight: "600",
                fontSize: "12px !important",
                fontFamily: "Poppins",
              },

              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color) !important",
                border: "none",
                color: "#ffffff!important",
                fontSize: "15px!important",
                fontWeight: "bold!important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                fontFamily: "Poppins",
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "#f5f5f5",
                minHeight: "352px!important",
                // maxHeight: "323px!important",
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "var(--pagination-bottom-color) !important",
                lineHeight: "38px!important",
                minHeight: "38px!important",
                maxHeight: "38px!important",
                borderRadius: "0 0 6px 6px",
                marginTop: "5px!important",
                marginTop: "5px!important",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "black!important",
                fontSize: "12px",
                width: "100px",
                marginBottom: "1px",
                fontFamily: "Poppins",
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#4b437cd1!important",
              },
              "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
                color: 'white', // Ensure filter icons and three-dot menu icons are white
              },

            }}
          >

            <DataGrid
              rowHeight={40}
              rows={pipelineRecords?.filter((item) =>
                toggle ? item.is_active === "Y" || item.is_active === 'N' : item.is_active === "Y"

              )}
              columns={columns}
              components={{ Toolbar: GridToolbar }}

              getRowClassName={(params) => {
                return params?.row?.is_active === 'N'

                  ? 'Mui-disabled-row'
                  : params.indexRelativeToCurrentPage % 2 === 0
                    ? 'Mui-odd'
                    : 'Mui-even';
              }}

              sortModel={[
                {
                  field: 'id',
                  sort: 'desc',
                },
              ]}
            />
          </Box>
        ) : !isRecordLoading ? (
          <>Error: Connection records are undefined</>
        ) : (
          <>Loading...</>
        )}
      </Box>
      <Popup
        title="Pipeline Form"
        openPopup={openPopup}
        width={"sm"}
        setOpenPopup={setOpenPopup}
      >
        <ObjectForm
          fieldsList={fieldsList}
          values={recordForEdit}
          addOrEdit={addOrEdit}
          setOpenPopup={setOpenPopup}
          object={"Pipeline"}
        />
      </Popup>
      <Popup
        title={`Task Sequence Canvas for pipeline - "${rowValue.id}_${rowValue.pipeline_name}"`}
        openPopup={openTaskSeqPopup}
        width={"md"}
        setOpenPopup={setOpenTaskSeqPopup}
      >
        <ReactFlowProvider> {/* Ensure ReactFlowProvider wraps around TaskSequenceFlow */}

          <TaskSequenceFlow
            pipelineTasks={pipelineTasks}
            addOrEditRecord={addOrEdit}
            formType={"Tasks Sequence"}
          />
        </ReactFlowProvider>
      </Popup>


      <Popup
        title="Pipelines"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>

      </Popup>
      <ToastContainer
        position="top-center"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ fontSize: '1.2em' }}
      />
    </>
  );
};

export default Pipelines;
