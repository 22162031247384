import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Avatar,
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReactMarkdown from 'react-markdown';
import {
  useGetConnectionsQuery,
  useGetConnectionFormQuery,
  useAddConnectionMutation,
  useUpdateConnectionMutation,
} from "../state/apiSlice";
import crypto from 'crypto';
import { ModeEditOutline } from "@mui/icons-material";
import FlexBetween from "../components/FlexBetween";
import ConnectionForm from "./ConnectionForm";
import Popup from "../components/Popup";
import Header from "../components/Header";
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import EditOffTwoToneIcon from '@mui/icons-material/EditOffTwoTone';
import useStyles from "useStyles";

function decrypt(data) {
  const KEY = '8ookgvdIiH2YOgBnAju6Nmxtp14fn8d3';
  const IV = 'rBEssDfxofOveRxR';
  let encrypted = data.slice(0, -32);
  let tag = data.slice(-32);
  const decipher = crypto.createDecipheriv('aes-256-gcm', KEY, IV);
  decipher.setAuthTag(Buffer.from(tag, 'hex'));
  let decrypted = decipher.update(encrypted, 'hex', 'utf8');
  decrypted += decipher.final('utf8');
  return decrypted;
}

const Connection = (props) => {
  const styles = useStyles();
  const { record, openInPopup, isDisabled, isTooltipOpen, setTooltipOpen, tooltipId } = props;

  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  let connection_subtype = record.connection_subtype;

  const handleTooltipOpen = () => {
    setTooltipOpen(record.id);
    setIsExpanded(true);
  };
  const handleTooltipClose = () => {
    setIsExpanded(false);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: "4px 5px 6px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(1 0 0 / 14%), 4px 2px 1px 1px rgb(0 0 0 / 12%) !important",
        background: "var(--card-bgcolor) !important",
        borderRadius: "0.55rem",
        padding: "2px",
        transition: 'transform 0.3s ease-in-out',
        borderLeft: "6px solid var(--primary-color) !important",
        '&:hover': {
          transform: 'scale(1.05)',
        },
        ...(isDisabled && {
          opacity: 0.5,
          pointerEvents: 'none',
          backgroundColor: "lightgrey"
        }),
      }}
    >
      <CardContent>
        <Box>
          <FlexBetween
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontWeight: "700!important",
                fontSize: "14px!important",
              }}
              gutterBottom
            >
              ID: {record.id}
            </Typography>
            <Box display="flex" alignItems="center" gap={1}>
              {record.fields_list && (
                <Tooltip
                  open={isTooltipOpen === record.id}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <CardContent
                      sx={{
                        backgroundColor: "white",
                        margin: "-8px",
                        borderRadius: "8px", // Adjust the value as needed
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                        border: "2px solid lightgrey" ,// Light grey border
                      }}
                    >
                      {record.fields_list.map((item) => {
                        const fieldName = Object.keys(item)[0];
                        const fieldValue = Object.values(item)[0];
                        return (
                          <Typography
                            key={fieldName}
                            sx={{
                              fontFamily: "Poppins, sans-serif",
                              padding: "3px",
                              fontSize: "12px",
                              color: "var(--primary-color)",
                            }}
                          >
                            {/* <Typography
                    component="span"
                    sx={{
                      fontWeight: "700", // Bold for fieldName
                      fontSize: "12px",
                    }}
                  >
                    {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}&#58;
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      fontWeight: "400", // Light for fieldValue
                      fontSize: "12px",
                      marginLeft: "5px", // Adds space between fieldName and fieldValue
                    }}
                  >
                    {fieldValue}
                  </Typography> */}


                            <Typography
                              component="span"
                              sx={{
                                fontWeight: "700", // Bold for fieldName
                                fontSize: "12px",
                              }}
                            >
                              {fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}&#58;
                            </Typography>
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: "400", // Light for fieldValue
                                fontSize: "12px",
                                marginLeft: "5px", // Adds space between fieldName and fieldValue
                              }}
                            >
                              {["password", "access_key", "secret_access_key"].includes(fieldName.toLowerCase())
                                ? "••••••••••••••••••"
                                : fieldValue}
                            </Typography>

                          </Typography>
                        );
                      })}
                    </CardContent>
                  }
                >
                  <AddCircleOutlineIcon
                    sx={{
                      ...styles.iconButton, // Keep your existing styles
                      cursor: "pointer",    // Add cursor pointer
                      marginTop: "-5px",
                      fontSize: "20px"
                    }}
                    onClick={handleTooltipOpen}
                  />
                </Tooltip>
              )}
              <Box
                color="secondary"
                onClick={() => {
                  if (record.option_write === "1") {
                    openInPopup(record);
                  }
                }}
                disabled={record.option_write !== "1"}
              >
                {record.option_write === "1" ? (
                  <ModeEditOutlineTwoToneIcon sx={{ ...styles.editButton, cursor: 'pointer', fontSize: "19px" }} />
                ) : (
                  <EditOffTwoToneIcon sx={{ ...styles.editButtonDisable, cursor: 'pointer', fontSize: "19px" }} />
                )}
              </Box>
            </Box>
          </FlexBetween>

        </Box>
        <Typography sx={{
          color: "var(--sidebar-select-color) !important",
          fontWeight: "600!important",
          fontFamily: "Poppins",
          fontSize: "13px",
        }}>
          {record.project_name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "600!important",
                color: "black",
                fontSize: "12px",
              }}
            >
              {record.connection_type}
            </Typography>
            <Typography
              sx={{
                fontWeight: "600!important",
                fontFamily: "Poppins",
                fontSize: "12px",
              }}
            >
              {record.connection_subtype}
            </Typography>
          </Box>
          <Avatar
            alt={record.connection_subtype}
            sx={{
              color: "black",
              width: 35,
              height: 35, // Ensure width and height are the same
              borderRadius: '50%', // Makes the Avatar circular
              objectFit: 'cover', // Ensures the image covers the Avatar area without distortion
            }}
            src={`assets/${record.connection_subtype
              .toLowerCase()
              .trim()
              .replace(" ", "_")}.png`}
          >
            {record.connection_subtype}
          </Avatar>

        </Box>

        <Typography
          component="div"
          sx={{
            color: 'black',
            fontWeight: "600!important",
            fontFamily: "Poppins",
            fontSize: "12px",
          }}
        >
          {record.connection_name}
        </Typography>
      </CardContent>
    </Card>
  );
};

const Connections = () => {
  const styles = useStyles();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const newuser = useSelector(selectCurrentUser);
  const [openPopupHelp, setOpenPopupHelp] = useState(false);
  const closeModal = () => {
    setOpenPopupHelp(false);
  };
  const [markdownContent, setMarkdownContent] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState(null); // Track open tooltip


  useEffect(() => {
    fetch('/docs/connection/connection.md')
      .then(response => response.text())
      .then(data => setMarkdownContent(data))
      .catch(error => console.error('Error fetching connection.md:', error));
  }, []);

  const user = newuser.login_id
  const { data: connectionRecords, isLoading: isRecordLoading, error: recordError } = useGetConnectionsQuery(user);
  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldsList] = useState(null);
  const [connformData, setConnFormData] = useState(null);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [addConnection] = useAddConnectionMutation();
  const [updateConnection] = useUpdateConnectionMutation();
  const { data: formData, isLoading: isFormLoading, error: formError } = useGetConnectionFormQuery(user);
  const [searchQuery, setSearchQuery] = useState('');
  if (formError) {
    console.error("Error fetching form data:", formError);

  } else if (!isFormLoading && initValue === null && fieldsList === null) {
    let initvalue = { ...formData[0]?.initialvalues };
    if (!("connection_type" in initvalue) && !("connection_subtype" in initvalue)) {
      initvalue["connection_type"] = "";
      initvalue["connection_subtype"] = "";
    }

    setInitValue(initvalue);
    setFieldsList(formData[0]?.field_list);
    setConnFormData(formData);
  }
  const currentUser = useSelector(selectCurrentUser);
  const addOrEditConn = async (values) => {

    // encrypt the password here
    if (values?.id > 0) {
      values.updated_by = currentUser.login_id;
      await updateConnection({ data: values, connection_id: values.id });
    } else {
      values.created_by = currentUser.login_id;

      await addConnection({ data: values });
    }

    const delayMilliseconds = 3000;
    await new Promise(resolve => setTimeout(resolve, delayMilliseconds));
    setRecordForEdit(null);
    setOpenPopup(true);
  };
  const [toggle, setToggle] = useState(true);

  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };
  // const handlePopupOpen = (record) => {
  //   setRecordForEdit(record);
  //   setOpenPopup(true);
  // };



  const handleTooltipToggle = (id) => {
    setTooltipOpen(tooltipOpen === id ? null : id); // Toggle or close tooltip
  };
  const openInPopup = (item) => {
    const matchingFormData = formData?.find(
      (data) =>
        data.connection_type === item.connection_type &&
        data.connection_subtype === item.connection_subtype
    );

    if (matchingFormData) {
      const decryptedFields = matchingFormData?.fields_list?.reduce(
        (decryptedObj, field) => {
          if (field.field_type === "password") {
            const matchingKey = field.field_id;
            const matchingItem = item.fields_list.find(
              (itemField) => Object.keys(itemField)[0] === matchingKey
            );
            if (matchingItem) {
              const decryptedValue = decrypt(matchingItem[matchingKey]);
              decryptedObj.push({ [matchingKey]: decryptedValue });
            }
          }
          return decryptedObj;
        },
        []
      );

      const mergedFields = item.fields_list.map((itemField) => {
        const key = Object.keys(itemField)[0];
        const decryptedField = decryptedFields.find(
          (decryptedField) => Object.keys(decryptedField)[0] === key
        );
        if (decryptedField) {
          return decryptedField;
        }
        return itemField;
      });

      setRecordForEdit({ ...item, fields_list: mergedFields });
      setOpenPopup(true);
    }
  };
  const filteredConnections = connectionRecords?.filter((record) =>
    record.project_name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <>
      <Box m="1.5rem 2.5rem">



        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header
            title="Connections"
            subtitle="View/Manage Connections"
          />
          <Box display="flex" alignItems="center" gap={2} marginTop={3}>
            <TextField
              variant="outlined"
              placeholder="Search By Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--primary-color) !important", // default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color) !important", // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color) !important", // border color when focused
                  },
                  height: "35px", // Match the height of buttons
                },
              }}
            />
            <ToggleButtonGroup
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="Toggle between Active and All"
              sx={{ borderRadius: "80px" }}
            >
              <ToggleButton
                value={true}
                aria-label="All"
                sx={{
                  ...styles.myBackground,

                  backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: !toggle ? "#ffffff" : styles.myBackground.color,
                  '&:hover': {
                    backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="Active"
                sx={{
                  ...styles.myBackground,
                  backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: toggle ? "#ffffff" : styles.myBackground.color, // Text color changes based on toggle state
                  '&:hover': {
                    backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                    color: toggle ? "#ffffff" : styles.myBackground.color, // Ensure hover color is consistent with the toggle state
                  },
                }}
                disabled={!toggle}
              >
                Active
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(initValue);
              }}
              sx={{
                ...styles.myBackground,
              }}
            >
              Create
            </Button>
            <Button
              onClick={() => {
                setOpenPopupHelp(true);

              }}
              sx={{
                ...styles.myBackgroundToggle,

              }}
            >
              Help
            </Button>
          </Box>
        </Box>
      </Box>
      <Box m="1.5rem 2.5rem">
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: 'auto', margin: 'auto', textAlign: 'center' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>
        ) : isRecordLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {filteredConnections
              .filter((record) => {
                if (toggle) {
                  return true;
                } else {
                  return record?.is_active === 'Y';
                }
              })
              .map((record) => {
                let isDisabled = record?.is_active === 'N';
                return (
                  <Connection
                    key={record.id}
                    record={record}
                    openInPopup={openInPopup}
                    isDisabled={isDisabled}
                    isTooltipOpen={tooltipOpen}
                    setTooltipOpen={handleTooltipToggle}
                    tooltipId={record.id}
                  />
                );
              })}
          </Box>
        )}
      </Box>


      <Popup
        title="Connection Form"
        openPopup={openPopup}
        width={"md"}
        setOpenPopup={setOpenPopup}
      >  {formError ? (
        <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
          <Typography>{formError?.data?.message}</Typography>
        </Alert>

      ) : (
        <ConnectionForm
          formData={connformData}
          value={recordForEdit}
          addOrEditConn={addOrEditConn}
          setOpenPopupForClosing={setOpenPopup}
        />)}
      </Popup>
      <Popup
        title="Connection"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>

      </Popup>
    </>
  );
};

export default Connections;