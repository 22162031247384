import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormHelperText,
  StepLabel,
  useTheme,
  Stepper,
  StepIcon,
  Select,
  Step,
  IconButton
} from "@mui/material";
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { DataGrid, GridToolbar, useGridApiContext } from '@mui/x-data-grid';
import BulkIngestionFormEdit from "./BulkIngestionFormEdit";
import {
  useGetConnectionOptionsQuery,
  useGetConnectionByIdQuery,
  useLazyGetBulkIngPreviewFileQuery,
} from "../state/apiSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BulkIngestionParamsForm from "./BulkIngestionParamsForm";
import BulkIngestionPropertiesForm from "./BulkIngestionPropertiesForm";
import BulkForm from "./BulkForm";
import { ModeEditOutline } from "@mui/icons-material";
import Popup from "components/Popup";
import Users from "./Users";
import { selectCurrentUser, selectCurrentToken } from "state/authSlice";
import { useSelector } from "react-redux";

const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject?.find((rec) => rec.section === section);

  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );

    return fieldRecord;
  }

  return sectionRecord;
};

const renderSelect = (params, options) => (
  <Select
    value={params.value}
    onChange={(e) => params.api.setEditCellValue({ ...params, value: e.target.value })}
    size="small"
    sx={{ height: 1, width: '100%' }}
    native
    autoFocus
  >
    <option key={null} value={null}>
      None
    </option>
    {options.map((option) => (
      <option key={option.label} value={option.label}>
        {option.label}
      </option>
    ))}
  </Select>
);

const BulkIngestionForm = (props) => {
  const { taskFormFields, values: editValue, addOrEditRecord,setOpenPopup } = props;
  const steps = ["Main", "Source", "Target", "Review & Save"];
  const theme = useTheme();

  const currentUser = useSelector(selectCurrentUser);

  const user = currentUser.login_id;
  const [getBulkPreviewDatabase] = useLazyGetBulkIngPreviewFileQuery();
  const { data: connectionOptions } = useGetConnectionOptionsQuery(user);

  const mainRecord = findSectionField(taskFormFields, "Main");
  const sourceRecord = findSectionField(taskFormFields, "Source");

  const targetRecord = findSectionField(taskFormFields, "Target");

  const [extractionType, setExtractionType] = useState([]);
  const fullLabel = extractionType.find(type => type.label === "Full");

  const [actionOnTable, setActionOnTable] = useState([]);
  const [auditFields, setAuditFields] = useState([]);
  const auditLabel = auditFields.find(type => type.label === "NO");

  const [numSelected, setNumSelected] = useState(0);
  const [mergedData, setMergedData] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const [editedRow, setEditedRow] = useState(null);
  const [rowData, setRowData] = useState(null);


  const [recordForEdit, setRecordForEdit] = useState(null);
  const [rowIdSQL, setRowIdSQL] = useState(null);

  const [openSqlExecutionFormPopup, setopenSqlExecutionFormPopup] =
    useState(false);





  useEffect(() => {
    const sourceRecord1 = findSectionField(taskFormFields, "Source");
    const new11 = sourceRecord1?.fields_list;

    const sourceRecords = new11?.filter(
      (field) => field.connection_subtype === "DB"
    );


    const task = sourceRecords[0]?.field_list;
    const taskExtract = task.filter(
      (field) => field.field_id === "src_extraction_type"
    );

    let taskFieldResult = [];
    if (taskExtract[0] && taskExtract[0]?.option_list) {
      taskExtract[0]?.option_list.forEach((element) => {
        taskFieldResult.push({
          value: element.id || element.value,
          label: element.name,
        });
      });

      setExtractionType(taskFieldResult);
    }
  }, [taskFormFields]);

  useEffect(() => {
    const targetRecord = findSectionField(taskFormFields, "Target");
    const new11 = targetRecord?.fields_list;

    const targetRecords = new11.filter(
      (field) => field.connection_subtype === "DB"
    );

    const task = targetRecords[0]?.field_list;
    const taskExtract = task?.filter(
      (field) => field.field_id === "tgt_action_on_table"
    );
    const taskExtractAudit = task.filter(
      (field) => field.field_id === "tgt_audit_fields"
    );

    let taskFieldResult = [];
    let taskAuditResult = [];
    if (taskExtract[0] && taskExtract[0]?.option_list) {
      taskExtract[0]?.option_list.forEach((element) => {
        taskFieldResult.push({
          value: element.id || element.value,
          label: element.name,
        });
      });


      setActionOnTable(taskFieldResult);
    }
    if (taskExtractAudit[0] && taskExtractAudit[0]?.option_list) {
      taskExtractAudit[0]?.option_list.forEach((element) => {
        taskAuditResult.push({
          value: element.id || element.value,
          label: element.name,
        });
      });


      setAuditFields(taskAuditResult);
    }
  }, [taskFormFields]);

  const mainForm = mainRecord?.fields_list || [];
  const sourceFormData = sourceRecord?.fields_list || [];
  const targetFormData = targetRecord?.fields_list || [];

  const [currSrcConnSubType, setCurrSrcConnSubType] = useState(null);
  const [currTgtConnSubType, setCurrTgtConnSubType] = useState(null);
  const [sourceForm, setSourceForm] = useState(null);
  const [targetForm, setTargetForm] = useState(null);
  const [previewInfo, setPreviewInfo] = useState(null);
  const [previewSchemaInfo, setPreviewSchemaInfo] = useState(null);

  const sourceFormFieldIds = sourceForm?.field_list?.map(
    (field) => field.field_id
  );
  const mainFormFieldIds = mainForm?.map(
    (field) => field.field_id
  );

  const targetFormFieldIds = targetForm?.field_list?.map(
    (field) => field.field_id
  );
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [stepFormValues, setStepFormValues] = useState({});
  const [formType, setFormType] = useState(null);
  useEffect(() => {
    if (activeStep === 0) {
      setFormType("main");
    } else if (activeStep === 1) {
      setFormType("source");
    } else if (activeStep === 2) {
      setFormType("target");
    } else if (activeStep === 3) {
      setFormType("review");
    }
  }, [activeStep]);


  const handleNext = () => {
    setStepFormValues((prevStepFormValues) => {
      const fieldsForStep = getFieldsForStep();

      const currentStepFormValues = prevStepFormValues[activeStep] || {};
      const updatedValues = { ...currentStepFormValues };
      fieldsForStep.forEach((field) => {
        if (field.field_id !== "pipeline_id") {
          updatedValues[field.field_name] = formValues[field.field_name];
        }
      });

      return {
        ...prevStepFormValues,
        [activeStep]: updatedValues,
      };
    });

    setFormValues({});

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    const currentStepFormValues = stepFormValues[activeStep] || {};

    const updatedFormValues = { ...currentStepFormValues };
    const fieldsForStep = getFieldsForStep();
    fieldsForStep.forEach((field) => {
      if (field.field_id !== "pipeline_id") {
        updatedFormValues[field.field_name] = formValues[field.field_name];
      }
    });
    setFormValues(updatedFormValues);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let initValues = {};

  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainForm?.initialvalues,
      ...sourceForm?.initialvalues,
      ...targetForm?.initialvalues,
    };
  } else {
    let { details, ...taskRecord } = editValue;
    if (Object.keys(details).length !== 0) {
      const mainValues = details.main;
      const nestedDetails = details.details;

      initValues = { ...taskRecord, ...mainValues };

      nestedDetails?.forEach(detail => {
        const { Source, Target, subtask } = detail;

        const sourceDetails = Object.entries(Source ?? {}).reduce((acc, [key, value]) => {
          acc[`src_${key}`] = value;
          return acc;
        }, {});

        const targetDetails = Object.entries(Target ?? {}).reduce((acc, [key, value]) => {
          acc[`tgt_${key}`] = value;
          return acc;
        }, {});

        // Include accumulated values in initValues
        initValues = { ...initValues, ...sourceDetails, ...targetDetails };

        if (subtask !== undefined) {
          initValues[subtask] = initValues[subtask] || {};
          initValues[subtask].source = { ...initValues[subtask].source, ...sourceDetails };
          initValues[subtask].target = { ...initValues[subtask].target, ...targetDetails };
        }
      });
    } else {
      initValues = { ...taskRecord };
    }
  }

  const keys = Object.keys(initValues);

  // Get the count of the array
  const arrayCount = keys.filter(key => !isNaN(key)).length;




  initValues.chunk_size = initValues?.chunk_size || 10000;
  // initValues.job_execution = initValues?.job_execution || "Engine";

  const validationSchema = validateSchemaObject(formType);

  const [errors, setErrors] = useState(null);

  if (sourceFormData === null || targetFormData === null) {
    setErrors("Form data for Main, Source or Target can't be null.");
  }

  const [selectedRowsTgt, setSelectedRowsTgt] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleFormSubmit = async (values, resetForm) => {

    const mainFormFields = mainForm
      .map((rec) => rec.field_id)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);
    const removeFields = [
      "id",
      "is_active",
      "source",
      "target",
      "dqcount",
      "project_name",
      "pipeline_name",
    ];
    const taskDetails = [];
    let excludedFields = [];
    let taskParameterValues = {};

    if (formType === "main" || formType === "source" || formType === "target" || formType === "review") {
      taskParameterValues = {
        ...Object.keys(values).reduce((acc, fieldId) => {
          if (!excludedFields.includes(fieldId)) {
            acc[fieldId] = values[fieldId];
          }
          return acc;
        }, {}),
        src_parameter_type: currSrcConnSubType,
      };
      const excludedFieldTask = ['task_name', 'task_description', 'pipeline_id'];
      const extractedValues = {};
      mainFormFieldIds.forEach(fieldId => {
        if (!excludedFieldTask.includes(fieldId)) {
          extractedValues[fieldId] = values?.[fieldId];
        }
      });

      mainFormFields.forEach((prop) => delete taskParameterValues[prop]);
      removeFields.forEach((prop) => delete taskParameterValues[prop]);

      Object.keys(extractedValues).forEach((fieldId, index) => {

        const taskDetail = {
          task_type: "Main",
          parameter_type: "task",

          sequence: index + 1 + Object.keys(taskParameterValues).length,
          is_active: "Y",
          [`key_01`]: fieldId,
          [`value_01`]: extractedValues[fieldId],
        };



        taskDetails.push(taskDetail);
      });


    }
    let parameterTypeForSelectedRow = 1;

    mergedData.forEach((selectedRow) => {

      switch (formType) {

        case "source":
          taskParameterValues = {
            ...Object.keys(values).reduce((acc, fieldId) => {
              if (!fieldId.startsWith("src_") && !excludedFields.includes(fieldId)) {
                acc[fieldId] = values[fieldId];
              }
              return acc;
            }, {}),
            src_parameter_type: currSrcConnSubType,
            ...sourceFormFieldIds.reduce((acc, fieldId) => {
              if (fieldId.startsWith("src_") && !excludedFields.includes(fieldId)) {
                acc[fieldId] = values[fieldId];
              }
              return acc;
            }, {}),
          };

          break;

        case "target":
          taskParameterValues = {
            ...Object.keys(values).reduce((acc, fieldId) => {
              if (!fieldId.startsWith("tgt_") && !excludedFields.includes(fieldId)) {
                acc[fieldId] = values[fieldId];
              }
              return acc;
            }, {}),
            tgt_parameter_type: currTgtConnSubType,

            ...targetFormFieldIds.reduce((acc, fieldId) => {
              if (fieldId.startsWith("tgt_") && !excludedFields.includes(fieldId)) {
                acc[fieldId] = values[fieldId];
              }
              return acc;
            }, {}),
          };
          break;

        default:
          break;
      }
      mainFormFields.forEach((prop) => delete taskParameterValues[prop]);
      removeFields.forEach((prop) => delete taskParameterValues[prop]);
      Object.keys(selectedRow).forEach((rowKey, index) => {
        if (rowKey !== 'id' && rowKey !== 'src_parameter_type' && rowKey !== 'tgt_parameter_type') {
          const valueKey = rowKey.replace("src_", "").replace("tgt_", "");
          let taskType = "DataQuality";

          if (rowKey.includes("src_")) {
            taskType = "Source";
          } else if (rowKey.includes("tgt_")) {
            taskType = "Target";
          }

          const taskDetail = {
            task_type: taskType,
            parameter_type: parameterTypeForSelectedRow,
            restartability: values?.restartability,
            chunk_size: values?.src_chunk_size,
            ingest: values?.ingest_all,
            sequence: index + 1 + Object.keys(taskParameterValues).length,
            is_active: "Y",
            [`key_01`]: valueKey,
            [`value_01`]: selectedRow[rowKey],
          };
          taskDetails.push(taskDetail);
        }
      }
      );

      parameterTypeForSelectedRow++;
    });


    const tblTask = {
      is_active: "Y",
      id: values?.id || 0,
      ...Object.fromEntries(mainFormFields.map((prop) => [prop, values[prop]])),
    };

    const taskRecord = { ...tblTask, details: taskDetails };


    try {
      addOrEditRecord(taskRecord);
      if(formType==='review')
        {
          
          if (taskRecord?.id === 0) {
            toast.success(`Task Created Successfully`, { position: "top-center" });
          } else {
            toast.success(`Task Updated Successfully`, { position: "top-center" });
          }
          setTimeout(() => {
            setOpenPopup(false);
          }, 3000);  // 5000 milliseconds = 5 seconds
        }
       else{
        
        if (taskRecord?.id === 0) {
          toast.success(`Task Created Successfully`, { position: "top-center" });
        } else {
          toast.success(`Task Updated Successfully`, { position: "top-center" });
        }
      }
    } catch (error) {
      toast.error("Form Submission Error");
    }
  };


  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values);
    },
  });

  const srcSourceOption = formik?.values?.source_type;
  const tgtTargetOption = formik?.values?.target_type;
  const [selectedConnsrc, setSelectedConnectionListSrc] = useState({});
  const [selectedConntgt, setSelectedConnectionListTgt] = useState({});

  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === srcSourceOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
      setSelectedConnectionListSrc(selectedConnectionList);
    } else {
      console.log("No matching connection type found for source_type");
    }
  }, [srcSourceOption, connectionOptions]);

  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === tgtTargetOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
      setSelectedConnectionListTgt(selectedConnectionList);
    } else {
      console.log("No matching connection type found for target_type");
    }
  }, [tgtTargetOption, connectionOptions]);


  const [sourceType, setSourceType] = useState(null);
  const [targetType, setTargetType] = useState(null);


  useEffect(() => {
    const sourceTypeFromFormik = formik?.values?.source_type;
    const targetTypeFromFormik = formik?.values?.target_type;

    setSourceType(sourceTypeFromFormik);
    setTargetType(targetTypeFromFormik);

  }, [formik?.values?.source_type, formik?.values?.target_type]);


  useEffect(() => {
    changeConnSubType("source", sourceType);
    changeConnSubType("target", targetType)
  }, [sourceType, targetType]);
  const [connectionType1, setConnectionType1] = useState(null);
  const [connectionType2, setConnectionType2] = useState(null);
  useEffect(() => {
    changeConnSubType('source', sourceType);
  }, [formik?.values?.src_connection_name, connectionType1]);


  useEffect(() => {
    changeConnSubType('target', targetType);
  }, [formik?.values?.tgt_connection_name, connectionType2]);

  async function changeConnSubType(type, connSubType) {
    if (type === "source") {
      const connSubType = sourceType;
      setCurrSrcConnSubType(connSubType);
      const srcSelectedItem = sourceFormData.find(
        (item) => item.connection_subtype === connSubType
      );

      if (srcSelectedItem) {
        const updatedFieldList = await Promise.all(
          srcSelectedItem.field_list.map(async (field) => {
            if (field.field_id === "src_connection_name") {
              return { ...field, option_list: selectedConnsrc };
            } else if (field.field_id === "src_schema_name") {
              const data = {
                connection_id: formik.values.src_connection_name,
              };

              const response = await getBulkPreviewDatabase({
                connectionType: connectionType1?.toLowerCase(),
                params: data,
              });
              if (response.isSuccess) {
                return { ...field, option_list: response.data.schemas || [] };
              } else {
                console.error('Error fetching schemas:', response.error.data.message);
                return field;
              }
            } else {
              return field;
            }
          })
        );

        setSourceForm((prevSourceForm) => ({
          ...prevSourceForm,
          ...srcSelectedItem,
          field_list: updatedFieldList,
        }));
      }
    }

    if (type === "target") {
      const connSubType = targetType;
      setCurrTgtConnSubType(connSubType);
      const tgtSelectedItem = targetFormData.find(
        (item) => item.connection_subtype === connSubType
      );

      if (tgtSelectedItem) {
        const updatedFieldList = await Promise.all(
          tgtSelectedItem.field_list.map(async (field) => {
            if (field.field_id === "tgt_connection_name") {
              return { ...field, option_list: selectedConntgt };
            } else {
              return field;
            }
          })
        );
        if (tgtSelectedItem) {
          const updatedFieldList = await Promise.all(
            tgtSelectedItem.field_list.map(async (field) => {
              if (field.field_id === "tgt_connection_name") {
                return { ...field, option_list: selectedConntgt };
              } else if (field.field_id === "tgt_schema_name") {
                const data = {
                  connection_id: formik.values.tgt_connection_name,
                };

                const response = await getBulkPreviewDatabase({
                  connectionType: connectionType2?.toLowerCase(),
                  params: data,
                });
                if (response.isSuccess) {
                  return { ...field, option_list: response.data.schemas || [] };
                } else {
                  console.error('Error fetching schemas:', response.error.data.message);
                  return field;
                }
              } else {
                return field;
              }
            })
          );

          setTargetForm((prevTargetForm) => ({
            ...prevTargetForm,
            ...tgtSelectedItem,
            field_list: updatedFieldList,
          }));
        }
      }
    }
  }

  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData?.forEach(({ field_id, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id]?.matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }
  const getFieldsForStep = () => {
    switch (activeStep) {
      case 0:
        return mainForm;
      case 1:
        return sourceFormData;

      case 2:
        return targetFormData;

      default:
        return [];
    }
  };




  function validateSchemaObject(section) {
    let validationObject = {};

    switch (section) {
      case "main":
        if (mainForm != null) {
          validationObject[section] = validateFormSchema(mainForm);
        }
        break;

      case "source":
        if (sourceForm != null) {
          validationObject[section] = validateFormSchema(
            sourceForm?.field_list
          );
        }
        break;
      case "target":
        if (targetForm != null) {
          validationObject[section] = validateFormSchema(
            targetForm?.field_list
          );
        }
        break;
      default:
        break;
    }

    return validationObject;
  }

  if (formType === "source" && currSrcConnSubType === null) {
    if (editValue && editValue.source !== "none") {
      changeConnSubType("source", editValue.source);
    } else {
      changeConnSubType("source", sourceFormData[0].connection_subtype);
    }
  }

  if (formType === "target" && currTgtConnSubType === null) {
    if (editValue && editValue.target !== "none") {
      changeConnSubType("target", editValue.target);
    } else {
      changeConnSubType("target", targetFormData[0].connection_subtype);
    }
  }



  const formTask = (formikProps) => {
    return (
      <BulkForm
        taskProps={mainRecord}
        formProps={formikProps}
        object={"Task"}
      />
    );
  };

  const souConnType = formik?.values?.source_type;
  const tgtConnType = formik?.values?.target_type;
  const formSourceTask = (formikProps, sourceConnType) => {

    const fieldSet = new Set();
    const modifiedFieldList = sourceForm?.field_list?.filter((field) => {
      const isExcluded = [
        "src_object_type",
        "src_object_name",
        "src_extraction_criteria",
        "src_extraction_type",
        "src_task_group",
      ].includes(field.field_id);
      if (souConnType === "Files" && tgtConnType === "Files") {
        const includedFields = [
          "src_connection_name",
          "src_file_path",
          "src_files_filter_name",
          "src_subfolder_included",
          "src_ftp_mode",
          "src_operation"
        ];
        return includedFields.includes(field.field_id);
      }
      if (souConnType === "Files" && tgtConnType === "DB") {
        const includedFields = [
          "src_connection_name",
          "src_file_path",
          "src_files_filter_name",
          "src_subfolder_included",
        ];
        return includedFields.includes(field.field_id);
      }
      if (!isExcluded) {
        if (field.field_id === "src_connection_name") {
          const isNotDuplicate = !fieldSet.has(field.field_name);

          if (isNotDuplicate) {
            fieldSet.add(field.field_name);
          }

          return isNotDuplicate;
        } else {
          return true;
        }
      }

      return false;
    });
    return (
      <BulkIngestionParamsForm
        taskProps={{
          currSrcConnSubType: sourceConnType,
          fieldList: modifiedFieldList,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleSourceConnSubTypeChange}
        object={"Source"}
      />
    );
  };

  const formTargetTask = (formikProps, targetConnType) => {

    const fieldSet = new Set();

    const modifiedFieldList = targetForm?.field_list?.filter((field) => {
      if (tgtConnType === "DB") {
        const isExcluded = [
          "tgt_object_name",
          "tgt_audit_fields",
          "tgt_action_on_table",
          "tgt_primary_key",
        ].includes(field.field_id);
        return !isExcluded;
      }

      if (souConnType === "Files" && tgtConnType === "Files") {
        const includedFields = [
          "tgt_connection_name",
          "tgt_file_path",
          "tgt_object_prefix_name",
          "tgt_object_sufix_name",
        ];
        return includedFields.includes(field.field_id);
      }

      if (field.field_id === "tgt_connection_name") {
        const isNotDuplicate = !fieldSet.has(field.field_name);

        if (isNotDuplicate) {
          fieldSet.add(field.field_name);
        }

        return isNotDuplicate;
      }

      return true; // Include all other fields by default
    });

    return (
      <BulkIngestionParamsForm
        taskProps={{
          currSrcConnSubType: targetConnType,
          fieldList: modifiedFieldList,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleTargetConnSubTypeChange}
        object={"Target"}
      />
    );
  };


  function handleSourceConnSubTypeChange(subTypeValue) {
    changeConnSubType("source", subTypeValue);
  }

  function handleTargetConnSubTypeChange(subTypeValue) {
    changeConnSubType("target", subTypeValue);
  }

  const [schemasData, setSchemasData] = useState(null);
  const handleChange = async () => {
    let data = {};
    let filetype;
    let connectionType;

    if (formType === "source") {
      connectionType = connectionType1?.toLowerCase().replace(/\s/g, "");
      filetype = formik?.values?.src_file_type;

      if (connectionType === "awss3" || connectionType === "localserver") {
        data = {
          connection_id: formik.values.src_connection_name,
          schema_name: formik.values.src_schema_name,
        };
      } else {
        data = {
          connection_id: formik.values.src_connection_name,
          schema_names: formik.values.src_schema_name,
          schema_name: formik.values.src_schema_name,
        };
      }
    }

    if (formType === "target") {
      connectionType = currTgtConnSubType?.toLowerCase().replace(/\s/g, "");
      filetype = formik.values.tgt_file_type;

      if (connectionType === "awss3" || connectionType === "localserver") {
        data = {
          connection_id: formik.values.src_connection_name,
          schema_name: formik.values.src_schema_name,
        };
      } else {
        data = {
          connection_id: formik.values.src_connection_name,
          schema_name: formik.values.src_schema_name,
        };
      }
    }

    let response;

    if (connectionType === "awss3" || connectionType === "localserver") {
      response = await getBulkPreviewDatabase({
        connectionType,
        filetype,
        params: data,
      });
    } else {
      response = await getBulkPreviewDatabase({ connectionType, params: data });
      setSchemasData(response?.data?.schemas);
    }

    try {
      if (response.isSuccess) {
        setPreviewSchemaInfo(response.data);
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    console.log(schemasData, "Schemas data outside handleChange");
  }, [schemasData]);
  useEffect(() => {
    handleChange();
  }, [formik?.values?.src_connection_name]);

  const [modifiedDataRows1, setModifiedDataRows1] = useState([]);
  const [modifiedDataRows, setModifiedDataRows] = useState([]);

  const handleBrowse = async () => {
    try {
      let data = {};
      let filetype;
      let connectionType;

      if (formType === "source") {
        connectionType = connectionType1?.toLowerCase().replace(/\s/g, "");
        filetype = formik?.values?.src_file_type;
        data = {
          connection_id: formik.values.src_connection_name,
          schema_names: formik.values.src_schema_name,
          schema_name: formik.values.src_schema_name,
        };
      }

      const connection_id = formik?.values?.src_connection_name;
      let response = await getBulkPreviewDatabase({ connectionType, params: data });

      if (response.isSuccess) {
        setPreviewInfo(response.data);

        const selectedSchemaNames = (response.data?.inputProps?.schema_names || formik?.values?.src_schema_name)?.split(',') || [];
        // const extractionTypeName = extractionType.length > 0 ? extractionType[0].name : null;

        setModifiedDataRows((prevDataRows) => {
          const dataRows = selectedSchemaNames.flatMap((selectedSchema, index) => {
            const schemaInfo = response.data?.results?.find((result) => result?.schema_name === selectedSchema);
            if (schemaInfo) {
              let taskGroupCounter = 0;
              return (schemaInfo.tables || []).concat(schemaInfo.views || []).map((objectName, index) => {
                if ((index + 1) % 5 === 0) {
                  taskGroupCounter += 1;
                }
                return {
                  id: `${selectedSchema}_${objectName}`,
                  src_connection_name: connection_id,
                  src_database_name: formik?.values?.src_database_name,
                  src_schema_name: selectedSchema,
                  src_object_type: schemaInfo.tables && schemaInfo.tables.includes(objectName) ? 'Table' : 'View',
                  src_object_name: objectName,
                  src_extraction_type: extractionType.length > 0 ? extractionType[0].label : null,
                  src_extraction_type: fullLabel ? "Full" : (extractionType.length > 0 ? extractionType[0].label : null),

                  src_extraction_criteria: '',
                  src_task_group: taskGroupCounter,
                };
              });
            }
            return [];
          });

          return dataRows;
        });
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [dataRowsFiles, setDataRowsFiles] = useState([]);


  const handleGo = async () => {
    try {
      let data = {};
      let filetype;
      let connectionType;

      if (formType === "source") {
        connectionType = connectionType1?.toLowerCase().replace(/\s/g, "");
        filetype = formik?.values?.src_file_type;
        data = {
          connection_id: formik?.values?.src_connection_name,
          filePath: formik?.values?.src_file_path,
          fileName: formik?.values?.src_files_filter_name,
          subFolder: formik?.values?.src_subfolder_included,
        };
      }

      const connection_id = formik?.values?.src_connection_name;
      let response = await getBulkPreviewDatabase({ connectionType, params: data });
      if (response.isSuccess) {
        setPreviewInfo(response.data);

        const fileInfo = response.data?.results || [];

        setDataRowsFiles((prevDataRows) => {
          const fileRows = fileInfo.flatMap((file, index) => {
            const filePathSegments = file.filePath.split('/');
            const fileNameWithoutExtension = file.name.split('.')[0];
            let taskGroupCounter = 0;
            if ((index + 1) % 5 === 0) {
              taskGroupCounter += 1;
            }
            return {
              id: `${filePathSegments[filePathSegments.length - 1]}_${fileNameWithoutExtension}`,
              src_object_name: file?.name,
              src_connection_name: connection_id,
              src_file_path: formik?.values?.src_file_path,
              src_files_filter_name: formik?.values?.src_files_filter_name,
              src_subfolder_included: formik?.values?.src_subfolder_included,
              src_object_type: file.extension_type,
              src_file_path: file.filePath,
              src_task_group: taskGroupCounter,
            };
          }

          );

          return fileRows;
        });
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };





  let formComponent = null;
  if (formType === "main") {
    formComponent = formTask(formik);
  } else if (formType === "source") {
    formComponent = formSourceTask(formik, currSrcConnSubType);
  } else if (formType === "target") {
    formComponent = formTargetTask(formik, currTgtConnSubType);
  }



  const srcId = formik?.values?.src_connection_name;
  const tgtId = formik?.values?.tgt_connection_name;

  const { data: srcData, isLoading: srcIsLoading, isError: srcIsError } = useGetConnectionByIdQuery(srcId || null);

  const { data: tgtData, isLoading: tgtIsLoading, isError: tgtIsError } = useGetConnectionByIdQuery(tgtId || null);

  // Handle source connection
  useEffect(() => {
    if (!srcIsLoading && !srcIsError && srcData) {
      const connectionDetails = srcData[0]?.connection_details;
      setConnectionType1(srcData[0]?.connection_subtype);

      // Check if src_connection_name has changed in formik values
      const isSrcConnectionNameChanged =
        formik?.values?.src_connection_name !== formik?.prevValues?.src_connection_name;

      if (isSrcConnectionNameChanged) {
        const databaseConnection = connectionDetails?.find((connection) =>
          connection.hasOwnProperty("database")
        );

        formik.setFieldValue("src_database_name", databaseConnection?.database);
      }
    }
  }, [srcData, srcIsLoading, srcIsError, formik?.values?.src_connection_name]);

  // Handle target connection
  useEffect(() => {
    if (!tgtIsLoading && !tgtIsError && tgtData) {
      const connectionDetails = tgtData[0]?.connection_details;
      setConnectionType2(tgtData[0]?.connection_subtype);

      // Check if tgt_connection_name has changed in formik values
      const isTgtConnectionNameChanged =
        formik?.values?.tgt_connection_name !== formik?.prevValues?.tgt_connection_name;

      if (isTgtConnectionNameChanged) {
        const databaseConnection = connectionDetails?.find((connection) =>
          connection.hasOwnProperty("database")
        );

        formik.setFieldValue("tgt_database_name", databaseConnection?.database);
      }
    }
  }, [tgtData, tgtIsLoading, tgtIsError, formik?.values?.tgt_connection_name]);


  const columns = [
    { field: "src_schema_name", headerName: "Source Schema", flex: 0.8 },
    { field: "src_object_type", headerName: "Type", flex: 0.4 },
    { field: "src_object_name", headerName: "Object Name", flex: 1 },
    {
      field: 'src_extraction_type',
      headerName: 'Extract Type',
      flex: 0.7,
      align: 'center',
      renderEditCell: (params) => renderSelect(params, extractionType),
      editable: true,
    },
    {
      field: "src_extraction_criteria",
      headerName: "Extract Criteria",
      flex: 1,
      editable: true,
    },
    { field: "src_task_group", headerName: "Group", flex: 0.5, editable: true, align: 'center' },
  ];
  const [rowEditValues, setRowEditValues] = useState({});
  const [globalEditedFields, setGlobalEditedFields] = useState({});

  const handleGlobalEdit = () => {
    // Reset any row-specific data to avoid conflicts when entering global edit mode
    setRecordForEdit(null); 
    setEditedRow(null);     
    setFormFields({});     
  
    // Set states specific to global edit
    setRowEditValues(1);         
    setGlobalEditedFields({ ...globalEditedFields });  
    setopenSqlExecutionFormPopup(true); 
  };
  
  const columnsFiles = [
    { field: "src_file_path", headerName: "Source File Path ", flex: 0.5 },
    { field: "src_object_name", headerName: "Source Object Name", flex: 0.5 },
    { field: "src_object_type", headerName: "Type", flex: 0.25 },

    {
      field: 'properties',
      headerName: 'File Properties',

      flex: 0.5,
      renderHeader: () => (
        <Button>
          File Properties
          <ModeEditOutline
            sx={{
              borderRadius: '6px!important',
              minWidth: '25px!important',
              minHeight: '25px!important',
              marginTop: '0px!important',
              marginRight: '5px!important',
              color: '#FFFFFF!important',
              fontWeight: 'bold!important',
              backgroundColor: '#0589f9ba!Important',
            }}
            onClick={handleGlobalEdit}
          />
        </Button>
      ),
      renderCell: ({ row }) => {


        const handleSqlExecutionCellClick = (row) => {
          setRecordForEdit(row);
          setopenSqlExecutionFormPopup(true);
          setRowIdSQL(row.id);
          setEditedRow(row.id);
          setFormFields(row);
          setFormTypeAndData('source', row);
        };


        return (
          <Box>
            <Button
              color="secondary"
              onClick={() => handleSqlExecutionCellClick(row)}

            >
              <ModeEditOutline
                sx={{
                  borderRadius: '6px!important',
                  minWidth: '25px!important',
                  minHeight: '25px!important',
                  marginTop: '0px!important',
                  marginRight: '30px!important',
                  color: '#FFFFFF!important',
                  fontWeight: 'bold!important',
                  backgroundColor: '#0589f9ba!Important',
                  marginLeft: '3px',
                }}
              />                   </Button>
          </Box>


        );


      }
    },

    { field: "src_task_group", headerName: "Group", flex: 1, editable: true },
  ];

  const columns1 = [

    { field: "src_object_name", headerName: "Source Object Name", flex: 1 },
    { field: "tgt_object_name", headerName: "Target Object Name", flex: 1, editable: true },

    {
      field: 'tgt_action_on_table',
      headerName: 'Action On Table',
      flex: 1,
      renderEditCell: (params) => renderSelect(params, actionOnTable),
      editable: true,
    },
    {
      field: "tgt_primary_key",
      headerName: "Primary Key",
      flex: 1,
      editable: true,
    },
    {
      field: 'tgt_audit_fields',
      headerName: 'Audit Fields',
      flex: 1,
      renderEditCell: (params) => renderSelect(params, auditFields),
      editable: true,
    },
  ];

  const selectedSchemaNames = previewInfo?.inputProps?.schema_names?.split(
    ","
  ) || [formik?.values?.src_schema_name];




  const [dataRowsTarget, setDataRowsTarget] = useState([]);
  const [dataRowsTargetFiles, setDataRowsTargetFiles] = useState([]);


  const [filteredDataRows, setFilteredDataRows] = useState([]);
  const [filteredDataRows1, setFilteredDataRows1] = useState([]);

  useEffect(() => {
    const updatedDataRowsTarget = modifiedDataRows.map((row) => {
      const defaultAuditField = auditLabel ? "NO" : (auditFields.length > 0 ? auditFields[0].label : null);
      const defaultActionOnTableItem = actionOnTable.find(item => item.label === "CREATE IF NOT EXIST");
      const defaultActionOnTable = defaultActionOnTableItem ? defaultActionOnTableItem.label : null;


      const matchingDataRow = dataRowsTarget.find(
        (targetRow) => targetRow.id === `${row.src_schema_name}_${row.src_object_name}`
      );
      const currentPrefix = formik?.values?.tgt_object_prefix_name || "";

      const updatedTgtObjectName = currentPrefix && matchingDataRow?.tgt_object_name.startsWith(currentPrefix)
        ? matchingDataRow?.tgt_object_name.slice(currentPrefix.length)
        : row.src_object_name;
      const fileNameWithoutExtension = updatedTgtObjectName.split('.')[0];

      return {
        id: `${row.src_schema_name}_${row.src_object_name}`,
        tgt_connection_name: formik?.values?.tgt_connection_name,
        tgt_database_name: formik?.values?.tgt_database_name,
        tgt_schema_name: formik?.values?.tgt_schema_name,
        src_schema_name: row.src_schema_name,
        src_object_name: row.src_object_name,
        tgt_object_name: currentPrefix + fileNameWithoutExtension,
        tgt_audit_fields: matchingDataRow?.tgt_audit_fields !== undefined
          ? matchingDataRow.tgt_audit_fields
          : defaultAuditField,
        tgt_primary_key: "",
        tgt_action_on_table: matchingDataRow?.tgt_action_on_table !== undefined
          ? matchingDataRow.tgt_action_on_table
          : defaultActionOnTable,
      };
    });

    setDataRowsTarget(updatedDataRowsTarget);
  }, [formik?.values?.tgt_object_prefix_name, modifiedDataRows, auditFields, actionOnTable, dataRowsTarget]);




  useEffect(() => {
    const updatedDataRowsTarget = dataRowsFiles.map((row) => {
      const defaultAuditField = auditLabel ? "NO" : (auditFields.length > 0 ? auditFields[0].label : null);
      const defaultActionOnTableItem = actionOnTable.find(item => item.label === "CREATE IF NOT EXIST");
      const defaultActionOnTable = defaultActionOnTableItem ? defaultActionOnTableItem.label : null;

      const matchingDataRow = dataRowsTargetFiles.find(

        (targetRow) => targetRow.id === `${row.src_object_type}_${row.src_object_name}`
      );

      const currentPrefix = formik?.values?.tgt_object_prefix_name || "";

      const updatedTgtObjectName = currentPrefix && matchingDataRow?.tgt_object_name.startsWith(currentPrefix)
        ? matchingDataRow?.tgt_object_name.slice(currentPrefix.length)
        : row.src_object_name;
      const fileNameWithoutExtension = updatedTgtObjectName.split('.')[0];

      return {
        id: `${row.src_object_name}`,
        tgt_connection_name: formik?.values?.tgt_connection_name,
        tgt_database_name: formik?.values?.tgt_database_name,
        tgt_schema_name: formik?.values?.tgt_schema_name,
        src_schema_name: row.src_schema_name,
        src_object_name: row.src_object_name,
        tgt_object_name: currentPrefix + fileNameWithoutExtension,
        tgt_audit_fields: matchingDataRow?.tgt_audit_fields !== undefined
          ? matchingDataRow.tgt_audit_fields
          : defaultAuditField,
        tgt_primary_key: "",
        tgt_action_on_table: matchingDataRow?.tgt_action_on_table !== undefined
          ? matchingDataRow.tgt_action_on_table
          : defaultActionOnTable,
      };
    });
    setDataRowsTargetFiles(updatedDataRowsTarget);
  }, [formik?.values?.tgt_object_prefix_name, dataRowsFiles, auditFields, actionOnTable]);

  const filesTargetData = dataRowsFiles.map((row) => {
    const defaultAuditField = auditLabel ? "NO" : (auditFields.length > 0 ? auditFields[0].label : null);
    const defaultActionOnTableItem = actionOnTable.find(item => item.label === "CREATE IF NOT EXIST");
    const defaultActionOnTable = defaultActionOnTableItem ? defaultActionOnTableItem.label : null;


    const matchingDataRow = dataRowsTarget.find(
      (targetRow) => targetRow.id === `${row.src_schema_name}_${row.src_object_name}`
    );
    const currentPrefix = formik?.values?.tgt_object_prefix_name || "";

    const updatedTgtObjectName = currentPrefix && matchingDataRow?.tgt_object_name.startsWith(currentPrefix)
      ? matchingDataRow?.tgt_object_name.slice(currentPrefix.length)
      : row.src_object_name;
    const fileNameWithoutExtension = updatedTgtObjectName.split('.')[0];

    return {
      id: `${row.src_object_name}`,
      tgt_connection_name: formik?.values?.tgt_connection_name,
      tgt_database_name: formik?.values?.tgt_database_name,
      tgt_schema_name: formik?.values?.tgt_schema_name,
      src_schema_name: row.src_schema_name,
      src_object_name: row.src_object_name,
      tgt_object_name: currentPrefix + fileNameWithoutExtension,
      tgt_audit_fields: matchingDataRow?.tgt_audit_fields !== undefined
        ? matchingDataRow.tgt_audit_fields
        : defaultAuditField,
      tgt_primary_key: "",
      tgt_action_on_table: matchingDataRow?.tgt_action_on_table !== undefined
        ? matchingDataRow.tgt_action_on_table
        : defaultActionOnTable,
    };
  });

  useEffect(() => {
    const updatedFilteredDataRows = dataRowsTarget.filter((row) =>
      selectedRows.some((selectedRow) => selectedRow.src_object_name === row.src_object_name)
    );
    // console.log(dataRowsTarget,updatedFilteredDataRows,"dataRowsTargetdataRowsTargetdataRowsTargetdataRowsTarget")
    setFilteredDataRows(updatedFilteredDataRows);
  }, [dataRowsTarget, selectedRows]);

  useEffect(() => {
    const updatedFilteredDataRows = dataRowsTargetFiles.filter((row) =>
      selectedRows.some((selectedRow) => selectedRow.src_object_name === row.src_object_name)
    );
    setFilteredDataRows1(updatedFilteredDataRows);
  }, [dataRowsTargetFiles, selectedRows]);


  let dataGroup = [];

  const numericKeys = Object.keys(initValues)
    .filter(key => typeof initValues[key] === 'object')
    .map(key => parseInt(key))
    .filter(key => !isNaN(key));

  const minGroup = Math.min(...numericKeys);


  const maxGroup = Math.max(...numericKeys);

  for (let i = minGroup; i <= maxGroup; i++) {
    if (initValues[i] && typeof initValues[i].source === 'object' && initValues[i].source !== null) {
      const id = `${initValues[i].source.src_schema_name}_${initValues[i].source.src_object_name}`;
      dataGroup.push({
        ...initValues[i].source,
        id: id,
      });
    } else {
      console.error(`initValues[${i}] or initValues[${i}].source is undefined or not an object.`);
    }
  }



  const [groupSelect, setGroupSelect] = useState(dataGroup);


  let dataGrouptgt = [];
  for (let i = minGroup; i <= maxGroup; i++) {
    if (initValues[i] && typeof initValues[i].target === 'object' && initValues[i].target !== null) {
      const id = `${initValues[i].target.tgt_schema_name}_${initValues[i].target.tgt_object_name}`;
      const src_object_name = `${initValues[i].source.src_object_name}`;

      dataGrouptgt.push({
        ...initValues[i].target,
        id: id,
        src_object_name: src_object_name
      });
    } else {
      console.error(`initValues[${i}] or initValues[${i}].target is undefined or not an object.`);
    }
  }


  const [groupSelectTgt, setGroupSelectTgt] = useState(dataGrouptgt);
  useEffect(() => {

    const updatedArray = groupSelectTgt.map(item => {
      return {
        ...item,
        tgt_connection_name: formik?.values?.tgt_connection_name,
        tgt_database_name: formik?.values?.tgt_database_name,
        tgt_schema_name: formik?.values?.tgt_schema_name,
      };
    });

    setGroupSelectTgt(updatedArray);

  }, [formik?.values?.tgt_schema_name]);

  const tgtValues = Object.entries(formik.values)
    .filter(([key]) => key.startsWith('tgt_'))
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});
  const srcValues = Object.entries(formik.values)
    .filter(([key]) => key.startsWith('src_'))
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});


  const mergeSelectedRows = () => {
    let mergedRows = [];

    if (souConnType === "DB" && tgtConnType === "DB") {
      if (dataRowsTarget && dataRowsTarget.length > 0) {
        mergedRows = selectedRows.map((sourceRow) => {
          const matchingTargetRow = dataRowsTarget.find((targetRow) => targetRow.src_object_name === sourceRow.src_object_name);
          if (matchingTargetRow) {
            return { ...sourceRow, ...matchingTargetRow };
          }
          return sourceRow;
        });
      } else {
        mergedRows = selectedRows.map((sourceRow) => {
          const matchingTargetRow = groupSelectTgt.find((targetRow) => targetRow.src_object_name === sourceRow.src_object_name);
          if (matchingTargetRow) {
            return { ...sourceRow, ...matchingTargetRow };
          }
          return sourceRow;
        });
      }
    }
    else {
      const commonMergedRows = { ...tgtValues, ...srcValues };

      if (souConnType === "Files" && tgtConnType === "Files") {
        mergedRows = [commonMergedRows];
      } else if (souConnType === "Files" && tgtConnType === "DB") {

        if (dataRowsTargetFiles && dataRowsTargetFiles.length > 0) {
          mergedRows = selectedRows.map((sourceRow) => {
            const matchingTargetRow = dataRowsTargetFiles.find((targetRow) => targetRow.src_object_name === sourceRow.src_object_name);
            if (matchingTargetRow) {
              return { ...sourceRow, ...matchingTargetRow };
            }
            return sourceRow;
          });
        } else {
          mergedRows = selectedRows.map((sourceRow) => {
            const matchingTargetRow = groupSelectTgt.find((targetRow) => targetRow.src_object_name === sourceRow.src_object_name);
            if (matchingTargetRow) {
              return { ...sourceRow, ...matchingTargetRow };
            }
            return sourceRow;
          });
        }
      } else {
        for (const item of selectedRows) {
          const mergedRow = { ...item, ...tgtValues, tgt_object_name: item.src_object_name };
          mergedRows.push(mergedRow);
        }
      }
    }

    setMergedData(mergedRows);
  };

  const [selectionModel, setSelectionModel] = useState([]);

  const allRows = modifiedDataRows.length > 0 ? modifiedDataRows : groupSelect;
  const allRows1 = dataRowsFiles.length > 0 ? dataRowsFiles : groupSelect;

  useEffect(() => {
    // Set selectionModel to include all row IDs initially
    setSelectionModel(allRows.map(row => row.id));
    setSelectedRows(allRows);
    setNumSelected(allRows.length);
  }, [allRows]);


  const renderDataGrid = () => {
    const commonStyles = {
      height: "50vh",
      "& .MuiDataGrid-row.Mui-odd": {
        backgroundColor: "var(--tertiary-color) !important",
        
      },
      "& .MuiDataGrid-row.Mui-disabled-row": {
        backgroundColor: "lightgrey",
      },
      "& .MuiDataGrid-root": {
        border: "none",
       fontWeight: "600",
        fontSize: "12px !important",
        fontFamily: "Poppins",

      },

      "& .MuiDataGrid-columnHeaders": {

        backgroundColor: "var(--primary-color) !important",
        border: "none",
        color: "#ffffff!important",
        fontSize: "15px!important",
        fontWeight: "bold!important",
        lineHeight: "38px!important",
        minHeight: "38px!important",
        maxHeight: "38px!important",
        fontFamily: "Poppins",
        borderBottom: "none",
      },
      "& .MuiDataGrid-footerContainer": {
 
        backgroundColor: "var(--pagination-bottom-color) !important",
        lineHeight: "38px!important",
        minHeight: "38px!important",
        maxHeight: "38px!important",
        borderRadius: "0 0 6px 6px",
        marginTop: "5px!important",
        marginTop: "5px!important",
      },
      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
        color: "#010e03!important",
        fontSize: "12px",
        width: "100px",
        marginBottom: "1px",
        fontFamily: "Poppins",
       
      },
      "& .MuiCheckbox-root.Mui-checked": {
        color: "#4b437cd1!important",
      },
      "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
        color: 'white', // Ensure filter icons and three-dot menu icons are white
      },

    }

    if (formType === "source" && souConnType === "DB") {
      return (
        <div style={{ height: "100%", width: "100%" }}>
          <Box m="30px 0 0 0" sx={{ ...commonStyles }}>
            <DataGrid
              rows={allRows}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);

                const newSelectedRows = allRows.filter(row =>
                  newSelectionModel.includes(row.id)
                );

                setSelectedRows(newSelectedRows);
                setNumSelected(newSelectedRows.length);
              }}

              onCellEditCommit={(params) => {

                const { id, field, value } = params;
                console.log(modifiedDataRows, 'modify');

                if (modifiedDataRows.length > 0) {
                  const updatedDataRows = modifiedDataRows.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setModifiedDataRows(updatedDataRows);
                } else if (groupSelect.length > 0) {
                  const updatedGroupSelect = groupSelect.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setGroupSelect(updatedGroupSelect);
                }
              }}

            />
          </Box>
        </div>
      );
    }


    if (formType === "source" && souConnType === "Files" && tgtConnType === "DB") {
      return (
        <div style={{ height: 400, width: "100%" }}>
          <Box m="30px 0 0 0" sx={{ ...commonStyles }}>
            <DataGrid
              key={JSON.stringify(allRows1)}
              rows={allRows1}
              columns={columnsFiles}
              checkboxSelection
              disableSelectionOnClick
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);

                const newSelectedRows = allRows1.filter(row =>
                  newSelectionModel.includes(row.id)
                );
                setSelectedRows(newSelectedRows);
                setNumSelected(newSelectedRows.length);
              }
              }



              onCellEditCommit={(params) => {


                const { id, field, value } = params;

                if (dataRowsFiles.length > 0) {
                  const updatedDataRows = dataRowsFiles.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setDataRowsFiles(updatedDataRows);
                } else if (groupSelect.length > 0) {
                  const updatedGroupSelect = groupSelect.map((row) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setGroupSelect(updatedGroupSelect);
                }
              }}

            />
          </Box>
        </div>
      );
    }
    if (formType === "target" && tgtConnType === "DB" && souConnType === "DB") {
      return (
        <div style={{ height: 400, width: "100%" }}>
          <Box m="30px 0 0 0" sx={{ ...commonStyles }}>
            <DataGrid
              rows={filteredDataRows.length > 0 ? filteredDataRows : groupSelectTgt}
              columns={columns1}

              onCellEditCommit={(params) => {
                const { id, field, value } = params;
                const updatedGroupSelectTgt = groupSelectTgt.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );
                setGroupSelectTgt(updatedGroupSelectTgt);
                const updatedDataRowsTarget = dataRowsTarget.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );
                setDataRowsTarget(updatedDataRowsTarget);

                const updatedFilteredDataRows = filteredDataRows.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );
                setFilteredDataRows(updatedFilteredDataRows);
              }}
            />
          </Box>
        </div>
      );
    }

    if (formType === "target" && tgtConnType === "DB" && souConnType === "Files") {
      return (
        <div style={{ height: 400, width: "100%" }}>
          <Box m="30px 0 0 0" sx={{ ...commonStyles }}>
            <DataGrid
              rows={filteredDataRows1.length > 0 ? filteredDataRows1 : groupSelectTgt}
              columns={columns1}


              onCellEditCommit={(params) => {
                const { id, field, value } = params;
                const updatedGroupSelectTgt = groupSelectTgt.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );
                setGroupSelectTgt(updatedGroupSelectTgt);

                const updatedDataRowsTarget = dataRowsTargetFiles.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );
                setDataRowsTargetFiles(updatedDataRowsTarget);
                const updatedFilteredDataRows = filteredDataRows1.map((row) =>
                  row.id === id ? { ...row, [field]: value } : row
                );

                setFilteredDataRows1(updatedFilteredDataRows);
              }}
            />
          </Box>
        </div>
      );
    }


    return null;

  };






  const [formFields, setFormFields] = useState({});




  const setFormTypeAndData = (formType, rowData) => {
    setFormType(formType);
    setRowData(rowData);
  };




  const [formValuesFromChild, setFormValuesFromChild] = useState(null);

  const handleFormValuesChange = (formValues) => {
    setFormValuesFromChild(formValues);
  };


  const [rows, setRows] = useState(dataRowsFiles);

  const handleSave = () => {
    let updatedRows;
    if (dataRowsFiles.length > 0) {
      updatedRows = [...dataRowsFiles];
    } else {
      updatedRows = [...groupSelect];

    }

    if (rowEditValues === 1) {
      updatedRows = updatedRows.map((row) => ({
        ...row,
        ...formValuesFromChild,
      }));
      setDataRowsFiles(updatedRows);
      setGroupSelect(updatedRows)


      setGlobalEditedFields({});
      setRowEditValues(0);
      setopenSqlExecutionFormPopup(false);

    }
    let editedRowIndex;
    if (dataRowsFiles.length > 0) {

      editedRowIndex = dataRowsFiles.findIndex((row) => row.id === editedRow);
    } else {
      editedRowIndex = groupSelect.findIndex((row) => row.id === editedRow);

    }

    if (editedRowIndex !== -1) {
      let updatedDataRows;
      if (dataRowsFiles.length > 0) {

        updatedDataRows = [...dataRowsFiles];

        updatedDataRows[editedRowIndex] = {
          ...updatedDataRows[editedRowIndex],
          ...formValuesFromChild,
        };
        setDataRowsFiles(updatedDataRows);
      }
      else {
        updatedDataRows = [...groupSelect];

        updatedDataRows[editedRowIndex] = {
          ...updatedDataRows[editedRowIndex],
          ...formValuesFromChild,
        };
        setGroupSelect(updatedDataRows)
      }


      setopenSqlExecutionFormPopup(false);
      setFormFields({});
    }
  };

  //   const handleSave = () => {
  //     let updatedRows = [...rows];
  // console.log(updatedRows,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  //     // Apply global edits to all rows if any
  //     if (Object.keys(globalEditedFields).length > 0) {
  //       updatedRows = updatedRows.map((row) => ({
  //         ...row,
  //         ...globalEditedFields,
  //       }));
  //       // Clear global edits after applying
  //       setGlobalEditedFields({});
  //     }

  //     // Apply local edits only to the edited row
  //     if (Object.keys(editedRow).length > 0) {
  //       updatedRows = updatedRows.map((row) =>
  //         row.id === editedRow.id ? { ...row, ...editedRow } : row
  //       );
  //     }

  //     setRows(updatedRows);
  //   };
  return (

    <>
      <Popup
        title={`File Properties: ${recordForEdit?.id}`}
        openPopup={openSqlExecutionFormPopup}
        width={"lg"}
        setOpenPopup={setopenSqlExecutionFormPopup}
      >
        <BulkIngestionFormEdit
          taskFormFields={taskFormFields}
          values={recordForEdit}
          addOrEditRecord={addOrEditRecord}
          formType={"bulkingestion"}
          setRecordForEdit={setRecordForEdit}
          rowIdSQL={rowIdSQL}
          onFormValuesChange={handleFormValuesChange}

        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
          <Button color="primary" variant="contained" onClick={() => handleSave()}>
            SAVE
          </Button>
        </Box>
      </Popup>

      {errors ? (
        <Box display="flex" flexDirection="row" sx={{ m: "3rem 1.5rem" }}>
          <Typography variant="h4">{errors}</Typography>
        </Box>
      ) : (
        <Box >
            <Box>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={(props) => {
                        const isActive = props.active;
                        const isCompleted = props.completed;
                        const bgColor = isActive
                          ? 'var(--primary-color)' // Blue when active
                          : isCompleted
                            ? '#4caf50' // Green when completed
                            : 'var(--secondary-color)'; // Light grey when inactive

                        return (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 30, // Increase size of the circle
                              height: 30, // Increase size of the circle
                              borderRadius: '50%',
                              backgroundColor: bgColor,
                              color: '#fff', // White text color
                            }}
                          >
                            {isActive ? (
                              <RadioButtonUnchecked />
                            ) : isCompleted ? (
                              <CheckCircle />
                            ) : (
                              <Typography variant="body2" fontSize={16}>
                                {index + 1}
                              </Typography>
                            )}
                          </div>
                        );
                      }}
                    >
                      <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Grid item xs={12} span={2} >
              {formComponent && formComponent.fields ? (
                <Grid container spacing={2}>
                  {formComponent.fields.map((field, index) => (
                    <Grid item xs={6} key={index}>
                      {field}
                    </Grid>
                  ))}
                </Grid>
              ) : formComponent}
            </Grid>

            {formik.errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Grid>
            )}

            {formType === "source" && souConnType === "DB" ? (
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleBrowse}
                sx={{
                  backgroundColor: "var(--primary-color)!important",
                  color: "#FFFFFF",
                  fontFamily: 'Poppins'
                }}
              >
                Browse
              </Button>
              </Box>
            ) : null}

            {formType === "source" && souConnType === "Files" && tgtConnType === "DB" ? (
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
             <Button
                type="submit"
                variant="contained"
                onClick={handleGo}
                sx={{
                  backgroundColor: "var(--primary-color)!important",
                  color: "#FFFFFF",
                  fontFamily: 'Poppins'
                }}
              >
                Go
              </Button>
              </Box>
            ) : null}

            {renderDataGrid()}
            {formType === "review" ? (
         <Box marginTop={4}>
         <Box display="flex" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Summary:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             Please review the below and Save.
             <br />
             Click on Back to make changes.
           </Box>
         </Box>
       
         <Box display="flex" alignItems="center" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Source:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             {formik?.values?.src_database_name} {formik?.values?.src_file_path} / {formik?.values?.src_schema_name} / {numSelected} Object(s)
           </Box>
         </Box>
       
         <Box display="flex" alignItems="center" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Target:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             {formik?.values?.tgt_database_name} {formik?.values?.tgt_file_path} / {formik?.values?.tgt_schema_name} / {numSelected} Object(s)
           </Box>
         </Box>
       
         <Box display="flex" alignItems="center" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Chunk:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             {formik?.values?.chunk_size}
           </Box>
         </Box>
       
         <Box display="flex" alignItems="center" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Restartability:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             {formik?.values?.restartability}
           </Box>
         </Box>
       
         <Box display="flex" alignItems="center" marginBottom="16px">
           <Typography variant="h6" style={{ fontFamily: 'Poppins' }}>
             Job Execution:
           </Typography>
           <Box style={{ fontFamily: 'Poppins', marginLeft: '8px' }}>
             {formik?.values?.job_execution}
           </Box>
         </Box>
       </Box>
       
            ) : null}


            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px', marginTop: '3%' }}> {/* Aligns the Box to the right */}
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  mergeSelectedRows();
                  formik.handleSubmit(mergedData);
                }}
                sx={{
                  backgroundColor: "var(--primary-color)!important",
                  color: "#FFFFFF",
                  fontFamily: 'Poppins'
                }}
              >
                Save
              </Button>

              {formType !== "main" && (
                <Button
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{
                    backgroundColor: "var(--primary-color)!important",
                    color: "#FFFFFF",
                    fontFamily: 'Poppins'
                  }}
                >
                  Back
                </Button>
              )}
              {formType !== "review" && (
                <Button
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                  sx={{
                    backgroundColor: "var(--primary-color)!important",
                    color: "#FFFFFF",
                    fontFamily: 'Poppins'
                  }}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )}

            </Box>

       
        
        </Box>
      )}
    </>
  );
};

export default BulkIngestionForm;